body {
  font-family: Helvetica, 'Neue Haas Grotesk', sans-self;
}

.text01 {
  padding-top: 141px;
  font-size: 40px;
  line-height: 1.3;
}

@media only screen and (max-width: 767px) {
  .text01 {
    padding-top: 72px;
    font-size: 32px;
    line-height: 1.3;
  }
}

.text02 {
  margin-top: 7px;
  line-height: 1.3;
  color: #888888;
  font-size: 32px;
}

@media only screen and (max-width: 767px) {
  .text02 {
    margin-top: 16px;
    font-size: 24px;
  }
}

.text03 {
  margin-top: 24px;
  line-height: 1.4;
}

.text03 a {
  text-decoration: underline;
}
